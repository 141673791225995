import { useEffect, useState } from "react";
import { globalHistory } from '@reach/router'
import ReactGA from 'react-ga';

export const useGaTracker = () => {
    // const GaSwitch = process.env.GATSBY_GA_SWITCH;
    // const location = globalHistory.location;
    // const [initialized, setInitialized] = useState(false);

    // useEffect(() => {
    //     if(GaSwitch === 'on' && !window.location.href.includes('localhost')) {
    //         // DONUE GA TRACKING CODE
    //         ReactGA.initialize(process.env.GATSBY_GA_UA_KEY, { debug: false });
    //     }

    //     setInitialized(true);
    // }, []);

    // // location이 변경되면 해당 path를 analytics pageview에 전송
    // useEffect(() => {
    //     if(initialized) {
    //         ReactGA.pageview(location.pathname + location.search);
    //     }
    // }, [initialized, location]);
}

export const GaEventTracter = (category, label) => {
    // const GaSwitch = process.env.GATSBY_GA_SWITCH;
    // if (GaSwitch === 'on') {
    //     // analytics event에 정보 전송
    //     ReactGA.event({
    //         category: category,
    //         action: '클릭',
    //         label: label
    //     })
    // } else {
    //     console.log('-----GA EVENT TRACKER-----');
    //     console.log('카테고리 : ' + category);
    //     console.log('액션 : ' + '클릭');
    //     console.log('라벨 : ' + label);
    //     console.log('--------------------------');
    // }
}

import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import Layout from "./src/components/layout";

import { Provider } from "react-redux";
import Cookies from "js-cookie";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./src/redux/store";
import { useChannelIO } from "./src/hooks/useChannelIO";
import OrganizeLinks from "./src/utils/organizeLinks";
import { useGaTracker } from "./src/utils/gaTracker";
import { ScrollToTop } from "./src/utils/scrollToTop";

const InitPage = () => {
    // ScrollToTop();
    return null;
};

const InitRoot = () => {
    //기본 url에 물린 session 쿠키 제거, 로컬 제외.
    if (window.location.hostname !== "localhost") {
        Cookies.remove("session");
        process.env.GATSBY_COOKIE_HOST !== window.location.host && Cookies.remove("session", { domain: window.location.host });
    }

    useChannelIO();
    // useGaTracker();
    // OrganizeLinks();

    return null;
};

export const wrapPageElement = ({ element, props }) => {
    return (
        <Layout {...props}>
            <InitPage />
            {element}
        </Layout>
    );
};

export const wrapRootElement = ({ element, props }) => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <InitRoot />
                {element}
            </PersistGate>
        </Provider>
    );
};
